import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { emailExpression } from '@common/forms/custom-validators';
import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { Community } from '@common/model/community';
import { CommunityService } from '@common/services/community.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { VwoService } from '@common/services/vwo.service';
import { NlAddressTools } from '@lang/nl/infrastructure/address-tools';
import { AddressService } from '@lang/nl/services/address.service';
import { CustomValidators } from '@spnl/forms/custom-validators';
import { BuyerPhase } from '@spnl/model/buyer-phase.enum';
import { Persona } from '@spnl/model/persona.enum';
import { RegisterStep } from '@spnl/model/register-step.enum';
import {
    CommunityMember,
    ExcludedReason,
    Registration,
} from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RegistrationService } from '@spnl/services/registration.service';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
} from 'rxjs/operators';

import {
    blockedCellPhoneNumbers,
    houseNrPattern,
    houseNrSuffixPattern,
    insertionPattern,
    namePattern,
    noSlashes,
    streetPattern,
} from '@lang/nl/validation';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-register-person',
    templateUrl: './person.component.html',
})
export class PersonComponent implements OnInit, OnDestroy, AfterViewInit {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    get submitting(): boolean {
        return this.registerStore.submitting;
    }

    get shouldShowStepCount(): boolean {
        return this.stepService.isInAlternativeFlow;
    }

    form: UntypedFormGroup;
    houseNumberSuffixFocus$ = new Subject<string>();
    houseNumberSuffixClick$ = new Subject<string>();
    submitRequested = false;

    hideAddressSpinners = true;
    readonlyAddress = true;

    destroyed$: Subject<void> = new Subject<void>();

    virtualFields = ['emailConfirmation'];

    community: Community;

    hasDuplicateRegistrations$: Observable<boolean>;

    houseNumberSuffixOptions: string[] = [];

    get allowPrevious(): boolean {
        const inRegistrationFlow = this.stepService.inRegistrationFlow(
            this.registration,
        );
        const hasPreviousStep = !!this.stepService.previousStep(
            this.registration,
        );
        return inRegistrationFlow && hasPreviousStep;
    }

    get email(): ControlWithErrors {
        return this.form.get('email');
    }

    get contactForm(): UntypedFormGroup {
        return this.form.get('contact') as UntypedFormGroup;
    }
    get salutation(): ControlWithErrors {
        return this.contactForm.get('salutation');
    }
    get firstName(): ControlWithErrors {
        return this.contactForm.get('firstName');
    }
    get insertion(): ControlWithErrors {
        return this.contactForm.get('insertion');
    }
    get lastName(): ControlWithErrors {
        return this.contactForm.get('lastName');
    }
    get cellPhone(): ControlWithErrors {
        return this.contactForm.get('cellPhone');
    }
    get phone(): ControlWithErrors {
        return this.contactForm.get('phone');
    }
    get street(): ControlWithErrors {
        return this.contactForm.get('street');
    }
    get houseNr(): ControlWithErrors {
        return this.contactForm.get('houseNr');
    }
    get province(): ControlWithErrors {
        return this.contactForm.get('province');
    }
    get municipality(): ControlWithErrors {
        return this.contactForm.get('municipality');
    }
    get longitude(): ControlWithErrors {
        return this.contactForm.get('longitude');
    }
    get latitude(): ControlWithErrors {
        return this.contactForm.get('latitude');
    }
    get houseNrSuffix(): ControlWithErrors {
        return this.contactForm.get('houseNrSuffix');
    }
    get zip(): ControlWithErrors {
        return this.contactForm.get('zip');
    }
    get city(): ControlWithErrors {
        return this.contactForm.get('city');
    }
    get agreesToFurtherContact(): ControlWithErrors {
        return this.contactForm.get('agreesToFurtherContact');
    }
    get agreesToConditions(): ControlWithErrors {
        return this.contactForm.get('agreesToConditions');
    }

    get productForm(): UntypedFormGroup {
        return this.form.get('product') as UntypedFormGroup;
    }

    get communityMember(): ControlWithErrors {
        return this.productForm.get('communityMember');
    }

    get termsAndConditionsUrl(): string {
        return this.router
            .createUrlTree([
                '/',
                this.communityService.communityCode,
                'terms-and-conditions',
            ])
            .toString();
    }

    get privacyStatementUrl(): string {
        return this.router
            .createUrlTree([
                '/',
                this.communityService.communityCode,
                'privacy-statement',
            ])
            .toString();
    }

    CommunityMember = CommunityMember;
    RegisterStep = RegisterStep;
    BuyerPhase = BuyerPhase;
    Persona = Persona;

    @ViewChild('instance', { static: true }) instance: NgbTypeahead;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private communityService: CommunityService,
        private addressService: AddressService,
        private registrationService: RegistrationService,
        private vwoService: VwoService,
        private locationService: RegisterLocationService,
        private registerStore: RegisterStoreService,
        private stepService: RegisterStepService,
        private registerSaveService: RegisterSaveService,
        private pendingChangesService: PendingChangesService,
        private digitalEventService: DigitalEventService,
    ) {
        this.form = this.fb.group({
            email: [
                '',
                {
                    validators: [
                        this.requiredWhenNoSnailMail(),
                        Validators.pattern(emailExpression),
                        Validators.maxLength(500),
                    ],
                    updateOn: 'blur',
                },
            ],
            contact: this.fb.group(
                {
                    salutation: [
                        '',
                        { validators: Validators.required, updateOn: 'change' },
                    ],
                    firstName: [
                        '',
                        [
                            Validators.required,
                            Validators.maxLength(100),
                            Validators.pattern(namePattern),
                        ],
                    ],
                    insertion: [
                        '',
                        [
                            Validators.maxLength(10),
                            Validators.pattern(insertionPattern),
                        ],
                    ],
                    lastName: [
                        '',
                        [
                            Validators.required,
                            Validators.maxLength(100),
                            Validators.pattern(namePattern),
                        ],
                    ],
                    street: [
                        '',
                        [
                            Validators.required,
                            Validators.pattern(noSlashes),
                            Validators.pattern(streetPattern),
                        ],
                    ],
                    houseNr: [
                        '',
                        [
                            Validators.required,
                            Validators.pattern(houseNrPattern),
                            Validators.maxLength(10),
                        ],
                    ],
                    province: [''],
                    municipality: [''],
                    longitude: [''],
                    latitude: [''],
                    cellPhone: ['', [CustomValidators.cellphoneValidator()]],
                    phone: ['', [CustomValidators.phoneValidator()]],
                    houseNrSuffix: [
                        '',
                        [
                            Validators.pattern(houseNrSuffixPattern),
                            Validators.maxLength(10),
                        ],
                    ],
                    agreesToFurtherContact: [false],

                    city: ['', [Validators.required]],
                    zip: ['', [Validators.required, this.zipValidator()]],

                    agreesToConditions: [
                        false,
                        {
                            validators: Validators.requiredTrue,
                            updateOn: 'change',
                        },
                    ],
                },
                {
                    validators: [
                        this.phoneRequiredValidator,
                        this.cellPhoneNotOnBlackListValidator,
                        this.agreesToConditionsValidator,
                    ],
                    updateOn: 'blur',
                },
            ),

            product: this.fb.group({
                communityMember: [null, [Validators.required]],
            }),
        });

        this.form.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.pendingChangesService.pendingChanges = this.form.dirty;
                this.saveRegistrationToSessionStorage();
            });

        this.zip.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                map((query) =>
                    query ? NlAddressTools.normalizeDutchZip(query) : null,
                ),
                filter((query) => query && NlAddressTools.truthyZip(query)),
                filter(
                    (query) =>
                        query &&
                        CustomValidators.matchesPostcodeRange(
                            query,
                            this.communityService.communityCode,
                        ),
                ),
                filter(
                    (_) =>
                        this.registration.excludedReason ===
                        ExcludedReason.PostcodeOutOfRange,
                ),
            )
            .subscribe(() => {
                this.registration.excluded = false;
                this.registration.excludedReason = null;
            });

        this.zip.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter((query) => query && NlAddressTools.truthyZip(query)),
                distinctUntilChanged(),
                debounceTime(300),
            )
            .subscribe((zip) => {
                if (
                    NlAddressTools.truthyZip(this.zip.value) &&
                    this.houseNr.value &&
                    this.houseNr.valid
                ) {
                    this.solveAndUpdateAddress(zip, this.houseNr.value);
                }
            });

        this.houseNr.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(),
                debounceTime(300),
            )
            .subscribe((houseNr) => {
                if (
                    this.houseNr.valid &&
                    this.zip.value &&
                    NlAddressTools.truthyZip(this.zip.value)
                ) {
                    this.solveAndUpdateAddress(this.zip.value, houseNr);
                }
            });
    }

    normalizeZip() {
        this.zip.setValue(NlAddressTools.normalizeDutchZip(this.zip.value));
    }

    formatPhone(field: ControlWithErrors) {
        field.setValue(field?.value?.replace(/-/g, ''));
    }

    private solveAndUpdateAddress(zip: string, houseNr: string) {
        if (zip && houseNr) {
            this.hideAddressSpinners = false;
            zip = zip.replace(/ /g, '');
            this.addressService
                .getAddress(zip, houseNr)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (address) => {
                        if (
                            address.City.length > 0 &&
                            address.Street.length > 0
                        ) {
                            this.city.setValue(address.City);
                            this.street.setValue(address.Street);
                            this.province.setValue(address.Province);
                            this.municipality.setValue(address.Municipality);
                            this.longitude.setValue(address.Longitude);
                            this.latitude.setValue(address.Latitude);
                            this.houseNumberSuffixOptions =
                                address.HouseNumberAdditions
                                    ? address.HouseNumberAdditions.split(
                                          ';',
                                      ).filter((hso) => hso)
                                    : [];
                            this.readonlyAddress = true;
                            this.hideAddressSpinners = true;
                        } else {
                            this.readonlyAddress = false;
                            this.hideAddressSpinners = true;
                        }
                    },
                    (error) => {
                        this.readonlyAddress = false;
                        this.hideAddressSpinners = true;
                    },
                );
        }
    }

    private saveRegistrationToSessionStorage(): void {
        this.applyFormValuesToRegistration(this.registration);
        this.registrationService.saveToSessionStorage(this.registration);
    }

    private phoneRequiredValidator(
        group: UntypedFormGroup,
    ): ValidationErrors | null {
        if (!!group.controls.phone.value || !!group.controls.cellPhone.value) {
            return null;
        }
        return { phoneRequired: true };
    }

    private cellPhoneNotOnBlackListValidator(
        group: UntypedFormGroup,
    ): ValidationErrors | null {
        if (!group.controls.cellPhone.value) {
            return null;
        }

        if (blockedCellPhoneNumbers.includes(group.controls.cellPhone.value)) {
            return { cellPhoneNumberInvalid: true };
        } else return null;
    }

    private zipValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            } else if (!NlAddressTools.truthyZip(control.value)) {
                return { pattern: { value: control.value } };
            }
            return null;
        };
    }

    get matchesPostcodeRange(): boolean {
        if (this.zip.value === null || this.zip.invalid) {
            return true;
        }
        return CustomValidators.matchesPostcodeRange(
            this.zip.value,
            this.communityService.communityCode,
        );
    }

    get introductionCmsKey(): string {
        if (this.registration.isInInterestedIndividualFlow) {
            return 'person-introduction-interested-individual';
        }

        if (this.registration.subscriptionComplete) {
            return 'person-introduction-editing';
        }

        return 'person-introduction';
    }

    ngOnInit(): void {
        this.communityService.community$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((community) => {
                this.registration.auction =
                    RegistrationService.getAuctionForRegistration(
                        community,
                        this.registration,
                    );
                this.community = community;
            });

        const formModel = makeFormModel(
            this.form,
            this.registration,
            this.virtualFields,
        );
        this.form.reset(formModel);
    }

    ngAfterViewInit() {
        this.digitalEventService.push('uw gegevens');
        this.vwoService.push('persondata');
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.applyFormValuesToRegistration(this.registration);

            this.registerSaveService.saveAndContinue();
            if (this.registration.subscriptionComplete) {
                this.pendingChangesService.pendingChanges = false;
            }
        }
    }

    public houseNumberSuffixValueOptions: OperatorFunction<
        string,
        readonly string[]
    > = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
        );
        const clicksWithClosedPopup$ = this.houseNumberSuffixClick$.pipe(
            filter(() => !this.instance.isPopupOpen()),
        );
        const inputFocus$ = this.houseNumberSuffixFocus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term) =>
                (term === ''
                    ? this.houseNumberSuffixOptions
                    : this.houseNumberSuffixOptions.filter(
                          (v) =>
                              v.toLowerCase().indexOf(term.toLowerCase()) > -1,
                      )
                )?.sort((a, b) => a.localeCompare(b)),
            ),
        );
    };

    public goBack() {
        this.locationService.goTo(
            this.stepService.previousStep(this.registration),
            new Substep.None(),
        );
    }

    public exclude() {
        this.registration.excluded = true;
        this.registration.excludedReason = ExcludedReason.PostcodeOutOfRange;
    }

    get showErrorsForSalutation(): boolean {
        return shouldShowErrorsFor(this.salutation);
    }

    get showErrorsForFirstName(): boolean {
        return shouldShowErrorsFor(this.firstName);
    }

    get showErrorsForInsertion(): boolean {
        return shouldShowErrorsFor(this.insertion);
    }

    get showErrorsForLastName(): boolean {
        return shouldShowErrorsFor(this.lastName);
    }

    get showErrorsForZip(): boolean {
        return shouldShowErrorsFor(this.zip);
    }

    get showErrorsForHouseNr(): boolean {
        return shouldShowErrorsFor(this.houseNr);
    }

    get showErrorsForHouseNrSuffix(): boolean {
        return shouldShowErrorsFor(this.houseNrSuffix);
    }

    get showErrorsForStreet(): boolean {
        return shouldShowErrorsFor(this.street);
    }

    get showErrorsForCity(): boolean {
        return shouldShowErrorsFor(this.city);
    }

    get showErrorsForEmail(): boolean {
        return shouldShowErrorsFor(this.email);
    }

    get showErrorsForCellPhone(): boolean {
        return shouldShowErrorsFor(this.cellPhone);
    }

    get showErrorsForPhone(): boolean {
        return shouldShowErrorsFor(this.phone);
    }

    get showErrorsForCommunityMember(): boolean {
        return shouldShowErrorsFor(this.communityMember);
    }

    get showErrorsForAgreesToConditions(): boolean {
        return (
            this.contactForm.hasError('agreesToConditionsRequired') &&
            this.agreesToConditions.touched
        );
    }

    public phoneInvalid(field: ControlWithErrors): boolean {
        return (
            (field.invalid || this.contactForm.hasError('phoneRequired')) &&
            field.touched
        );
    }

    public get phoneMissing(): boolean {
        return (
            (this.cellPhone.touched || this.phone.touched) &&
            this.contactForm.hasError('phoneRequired')
        );
    }

    get cellPhoneInvalid(): boolean {
        const cellPhoneFieldTouched = Boolean(this.cellPhone?.touched);

        return (
            cellPhoneFieldTouched &&
            this.contactForm.hasError('cellPhoneNumberInvalid')
        );
    }

    public get shouldBeExcluded(): boolean {
        return (
            this.registration && this.zip.valid && !this.matchesPostcodeRange
        );
    }

    private requiredWhenNoSnailMail(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form &&
                (!this.registration ||
                    !this.registration.communicationBySnailMail) &&
                !this.registrationService.snailMail &&
                !control.value
                ? { required: true }
                : null;
        };
    }

    private applyFormValuesToRegistration(registration: Registration) {
        applyFormValue(this.form, registration, this.virtualFields);
        if (registration.contact.zip != null) {
            registration.contact.zip = registration.contact.zip.trim();
        }
        this.registration.contact.agreesToFurtherContact =
            !!this.registration.contact.agreesToFurtherContact;
    }

    private agreesToConditionsValidator(
        group: UntypedFormGroup,
    ): ValidationErrors | null {
        return group.controls.agreesToConditions?.value !== true
            ? { agreesToConditionsRequired: true }
            : null;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
