import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AddressService {
    protected url =
        'https://ichoosr-addresses.azurewebsites.net/api/GetNlAddress';

    constructor(protected http: HttpClient) {}

    getAddress(zipCode: string, houseNr: string): Observable<AddressResponse> {
        return this.http.get<AddressResponse>(
            `${this.url}?zipCode=${zipCode}&houseNr=${houseNr}`,
        );
    }
}

// TODO: (SPNL-586) Make all properties here lowercase by removing JSON attribute on API model
export interface AddressResponse {
    City: string;
    Street: string;
    Province: string;
    Municipality: string;
    Longitude: number;
    Latitude: number;
    HouseNumberAdditions: string;
    AllowManualEntry: boolean;
}
