<h5
    *ngIf="useTopLevelTitle"
    [innerHTML]="'proposal-your-advantage-title' | cmsText | async"
></h5>
<span
    *ngIf="
        useTopLevelTitle &&
        (contentService.valueExists(
            'proposal-guidance-maximizing-selfconsumption'
        ) | async)
    "
    [innerHTML]="
        'proposal-guidance-maximizing-selfconsumption' | cmsText | async
    "
></span>
<div *ngIf="installations" class="card my-3 pb-3">
    <h5
        *ngIf="!useTopLevelTitle"
        class="card-header border-0"
        [innerHTML]="'proposal-your-advantage-title' | cmsText | async"
    ></h5>
    <div class="mt-3">
        <div
            class="px-3 d-flex flex-sm-row flex-column flex-wrap align-items-sm-start align-items-center"
        >
            <div class="my-auto">
                <img
                    class="mr-sm-3 me-0 mb-sm-0 mb-2"
                    [attr.src]="'proposal-capacity-img' | cmsImageSrc | async"
                    [attr.alt]="'proposal-capacity-img' | cmsImageAlt | async"
                    height="60"
                />
            </div>
            <div class="my-auto text-sm-left text-center">
                <span
                    [innerHTML]="'proposal-capacity-label' | cmsText | async"
                ></span>
                <hr class="d-sm-none d-block" />
            </div>
            <div class="ms-sm-auto my-auto pl-0">
                <b>{{
                    'kwh-capacity'
                        | cmsText
                            : [{ capacity: capacity | roundToNearest : '10' }]
                        | async
                }}</b>
                <app-spnl-tooltip
                    cmsKey="proposal-capacity-tooltip"
                ></app-spnl-tooltip>
            </div>
        </div>
        <hr />
        <div
            class="px-3 d-flex flex-sm-row flex-column flex-wrap align-items-sm-start align-items-center"
        >
            <div class="my-auto text-sm-left text-center">
                <img
                    class="mr-sm-3 me-0 mb-sm-0 mb-2"
                    [attr.src]="
                        'proposal-co2-reduction-img' | cmsImageSrc | async
                    "
                    [attr.alt]="
                        'proposal-co2-reduction-img' | cmsImageAlt | async
                    "
                    height="60"
                />
            </div>
            <div class="my-auto text-sm-left text-center">
                <span
                    [innerHTML]="
                        'proposal-co2-reduction-label' | cmsText | async
                    "
                ></span>
                <hr class="d-sm-none d-block" />
            </div>
            <div class="ms-sm-auto my-auto pl-0">
                <b>{{
                    'co2-reduction-kg'
                        | cmsText
                            : [
                                  {
                                      co2Reduction:
                                          co2Reduction | roundToNearest : '10'
                                  }
                              ]
                        | async
                }}</b>
                <app-spnl-tooltip
                    cmsKey="proposal-co2-reduction-tooltip"
                ></app-spnl-tooltip>
            </div>
        </div>
    </div>
</div>
