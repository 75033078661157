import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContentService } from '@common/services/content.service';
import { Registration } from '@spnl/model/registration';
import { SolarInstallations } from '@spnl/model/solar-installations';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-proposal-advantage',
    templateUrl: './proposal-advantage.component.html',
})
export class ProposalAdvantageComponent {
    @Input()
    registration: Registration;

    @Input()
    useTopLevelTitle: boolean;

    @Input()
    premiumPanelsChosen: boolean;
    @Input()
    numberOfPanelsChosen: number;

    @Input()
    form: UntypedFormGroup;

    constructor(
        public contentTranslatorService: ContentTranslatorService,
        private store: RegisterStoreService,
        public contentService: ContentService,
    ) {}

    get installations(): SolarInstallations {
        return this.store.installations;
    }

    get capacity(): number {
        return this.installations.capacityOf(
            this.premiumPanelsChosen,
            this.numberOfPanelsChosen,
        );
    }

    get saving(): number {
        return this.installations.savingOf(
            this.premiumPanelsChosen,
            this.numberOfPanelsChosen,
        );
    }

    get paybackTime(): number {
        return this.installations.paybackTimeOf(
            this.premiumPanelsChosen,
            this.numberOfPanelsChosen,
        );
    }

    get co2Reduction(): number {
        return this.installations.co2ReductionKgOf(
            this.premiumPanelsChosen,
            this.numberOfPanelsChosen,
        );
    }
}
